import { graphql } from "gatsby";
import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx"

import Seo from "../components/seo";
import Layout from "../components/layout";
import GalleryDetail from "../components/gallery-detail";
import GalleryCarousel from "../components/gallery-carousel";


export default function GeneratedPage({ data: { mdx } }) {
  const { frontmatter, body, fields, wordCount } = mdx;
  let contents = []
  const renderedBody = (<MDXRenderer>{ body }</MDXRenderer>)
  switch (frontmatter.template) {
    case 'gallery':
      contents.push((
        <div className="order-3 sm:order-2">
          <GalleryDetail renderedBody={renderedBody} wordCount={wordCount.words} thumbnails={fields.thumbnails} />
        </div>
      ))
      contents.push((
        <div id="exhibition-slideshow" className="responsive-slideshow order-2 sm:order-3">
          <GalleryCarousel images={fields.hires} />
        </div>
      ))
      break
    default:
      contents.push(renderedBody)
  }
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <h1>{frontmatter.title}</h1>
      { contents.map(content => content) }
    </Layout>
  );
}


export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      fields {
        slug
        siblings {
          id
          slug
          order
          title
        }
        thumbnails: gallery {
          fields {
            catalog_number
            order
            size
            source
            title
            bluethumb_link
          }
          gatsbyImageData(
            width: 144
            height: 144
            layout: CONSTRAINED
            transformOptions: {fit: CONTAIN}
            placeholder: DOMINANT_COLOR
            backgroundColor: "transparent"
          )
        }
        hires: gallery {
          fields {
            catalog_number
            order
            size
            source
            title
          }
          gatsbyImageData(
            width: 1200
            height: 1200
            layout: CONSTRAINED
            placeholder: BLURRED
            backgroundColor: "transparent"
          )
          resize {
            aspectRatio
          }
        }
        descendents {
          id
          order
          slug
          title
        }
      }
      frontmatter {
        date
        description
        template
        order
        title
      }
      wordCount {
        words
      }
    }
  }
`;
