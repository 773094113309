import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


function getWidthClass(cols) {
  // This ensures that all the width classes are included in tailwind after purge
  switch (cols) {
    case 0:
      return `sm:w-0`
    case 1:
      return `sm:w-1/12`
    case 2:
      return `sm:w-2/12`
    case 3:
      return `sm:w-3/12`
    case 4:
      return `sm:w-4/12`
    case 5:
      return `sm:w-5/12`
    case 6:
      return `sm:w-6/12`
    case 7:
      return `sm:w-7/12`
    case 8:
      return `sm:w-8/12`
    case 9:
      return `sm:w-9/12`
    case 10:
      return `sm:w-10/12`
    case 11:
      return `sm:w-11/12`
    case 12:
      return `sm:w-full`
    default:
      return ``
  }
}


function balanceTwoColumns(wordCount, imageCount, wordsPerThumb) {
  if (!wordsPerThumb) {
    wordsPerThumb = 12;
  }
  const textFlexColumns = Math.round(
    (wordCount /
      (wordCount + imageCount * wordsPerThumb)
    ) * 12
  )
  let textFlexWidthClass;
  if (textFlexColumns === 0) {
    textFlexWidthClass = `sm:w-0`;
  } else if (textFlexColumns === 12) {
    textFlexWidthClass = `sm:w-full`
  } else if (textFlexColumns < 3) {
    textFlexWidthClass = `sm:w-3/12`
  } else if (textFlexColumns > 9) {
    textFlexWidthClass = `sm:w-9/12`
  } else {
    textFlexWidthClass = getWidthClass(textFlexColumns)
  }
  const imageFlexColumns = Math.round(
    (imageCount * wordsPerThumb /
      (wordCount + imageCount * wordsPerThumb)
    ) * 12
  )
  let imageFlexWidthClass;
  if (imageFlexColumns === 0) {
    imageFlexWidthClass = "sm:w-0";
  } else if (imageFlexColumns === 12) {
    imageFlexWidthClass = "sm:w-full";
  } else if (imageFlexColumns < 3) {
    imageFlexWidthClass = `sm:w-3/12`;
  } else if (imageFlexColumns > 9) {
    imageFlexWidthClass = `sm:w-9/12`;
  } else {
    imageFlexWidthClass = getWidthClass(imageFlexColumns);
  }
  return [textFlexWidthClass, imageFlexWidthClass]
}


export default function GalleryDetail({ renderedBody, wordCount, thumbnails }) {
  const [textFlexWidthClass, imageFlexWidthClass] = balanceTwoColumns(
    wordCount,
    thumbnails.length
  )
  const thumbs = thumbnails.sort((a, b) => a.fields.order > b.fields.order)
  return (
    <div className="sm:flex sm:gap-4">
      <div className={ `${textFlexWidthClass} sm:flex-grow` }>
        { renderedBody }
      </div>
      <ul className={ `${imageFlexWidthClass} sm:flex-grow flex flex-wrap` }>
    {thumbs.map((thumb, index) => {
      const image = (
        <figure className="w-full flex flex-col items-center">
          <GatsbyImage key={index} image={getImage(thumb.gatsbyImageData)} alt={thumb.fields.title} />
          <figcaption className="text-center w-full leading-none mt-1">{thumb.fields.title}</figcaption>
        </figure>
      )
      if (thumb.fields.bluethumb_link === null) {
        return (<li className="m-0 p-1 w-1/2 sm:w-36">{image}</li>)
      } else {
        return (<li className="m-0 p-1 w-1/2 sm:w-36"><a className="block border-b-0 hover:bg-gray-300 hover:text-black" href={thumb.fields.bluethumb_link}>{image}</a></li>)
      }
    })}
      </ul>
    </div>
  )
}
