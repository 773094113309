import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


export default function GalleryCarousel({images, duration, initDelay, transition}) {
  const backgroundRefs = [];
  const backgroundWrappers = [];
  const slides = images
    .sort((a, b) => a.fields.order > b.fields.order)
    .map(
      (image, index) => {
        if (image) {
          const width = image.resize.aspectRatio > 1
                                                 ? 100
                                                 : image.resize.aspectRatio * 100
          const height = image.resize.aspectRatio > 1
                                                  ? (1 / image.resize.aspectRatio) * 100
                                                  : 100
          const containerStyle = {
            opacity: index ? 0 : 1,
            transition: `opacity ${transition}s`,
          }
          const imageStyle = {
            left: `${(100 - width) / 2}%`,
            width: `${width}%`,
            height: `${height}%`,
            top: `${(100 - height) / 2}%`
          }
          backgroundRefs[index] = React.createRef()
          let caption = image.fields.title
          if (image.fields.size) {
            caption = caption + ` (${image.fields.size} cm)`
          }
          return (
            <React.Fragment>
              <div  key={image.id} ref={backgroundRefs[index]} style={containerStyle} className="slideshow--item--container">
                <div className="slideshow--item--image">
                  <GatsbyImage image={getImage(image.gatsbyImageData)} style={imageStyle} />
                </div>
                <div className="slideshow--item--caption">
                  { caption }
                </div>
              </div>
            </React.Fragment>
          )
        } else {
          return ""
        }
      }
    );

  const [timeoutHandle, setTimeoutHandle] = useState(0);
  const timeoutHandleRef = useRef(timeoutHandle);
  timeoutHandleRef.current = timeoutHandle;
  const [index, setIndex] = useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;

  const clearAndSetTimeoutHandle = (newTimeoutHandle) => {
	clearTimeout(timeoutHandleRef.current);
	setTimeoutHandle(newTimeoutHandle);
  }

  const initEffect = () => {
    backgroundRefs.forEach(
      (backgroundRef) => {
        backgroundWrappers.push(backgroundRef.current);
      }
    );

    const length = backgroundWrappers.length;

    const changeIndex = function(newIndex) {
      const index = indexRef.current;
      clearTimeout(timeoutHandleRef.current);
      backgroundWrappers[index].style.opacity = 0;
      backgroundWrappers[newIndex % length].style.opacity = 1;
      setIndex((newIndex) % length);
      clearAndSetTimeoutHandle(setTimeout(advance, duration*1000))
    }

    const advance = function() {
      const index = indexRef.current;
      changeIndex(index + 1);
    }

    clearAndSetTimeoutHandle(setTimeout(advance, initDelay*1000))

    return () => {
	  clearTimeout(timeoutHandleRef.current);
    }
  }
  useEffect(initEffect, []);

  return <React.Fragment>{slides}</React.Fragment>	
}

GalleryCarousel.defaultProps = {
  images: [],
  duration: 5,
  transition: 1,
  initDelay: 5,
}

GalleryCarousel.propTypes = {
  images: PropTypes.array.isRequired,
  duration: PropTypes.number,
  transition: PropTypes.number,
  initDelay: PropTypes.number,
}
